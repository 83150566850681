import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function HolidayList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false, label: "Select" },
        { key: "holiday_name", sortable: true, label: "Holiday Name" },
        { key: "shifts", sortable: false, label: "Shifts" },
        { key: "date", sortable: true, label: "Date" },
        { key: "holiday_type", sortable: true, label: "Type" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalHolidayList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const holidaysMetaData = ref([]);
    const filterByType = ref(null);
    const typeOption = ref([]);
    const filterByShift = ref(null);
    const shiftOption = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalHolidayList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [currentPage, perPage, searchQuery, filterByType, filterByShift],
        () => {
            refetchData();
        }
    );

    const fetchHolidayList = (ctx, callback) => {
        store
            .dispatch("app-holidays/fetchHolidays", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                holiday_type: filterByType.value,
                shift: filterByShift.value
            })
            .then(response => {
                const holiday = response.data.data;
                const { total } = response.data.meta;

                callback(holiday);
                totalHolidayList.value = total;
                holidaysMetaData.value = holiday;
                shiftOption.value = response.data.shiftOption;
                typeOption.value = response.data.typeOption;
            })
            .catch(error => {
                console.error(error);
                // toast({
                //     component: ToastificationContent,
                //     props: {
                //         title: "Error fetching holidays",
                //         icon: "AlertTriangleIcon",
                //         variant: "danger"
                //     }
                // });

                toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error fetching holidays`,
                      icon:'AlertTriangleIcon',
                      variant : 'danger',
                      hideClose: true,
                      
                    },
                        },
                        {
                    timeout : 3000,
                    position : 'bottom-center',
                    toastClassName:"error-info",
                    hideProgressBar : true,
                        })
            });
    };

    const resolveHolidayShiftVariant = holiday_type => {
        if (holiday_type == 'Restricted Holiday') return "danger";
        if (holiday_type == 'Fixed Holiday') return "success";
        return "primary";
    };

    return {
        fetchHolidayList,
        tableColumns,
        perPage,
        currentPage,
        totalHolidayList,
        holidaysMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveHolidayShiftVariant,
        filterByType,
        typeOption,
        filterByShift,
        shiftOption
    };
}
